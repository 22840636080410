.imagee{
    height: 150px;
    width: 100%;
    border-radius: 30px;
}
.label{
    margin-bottom: 10px;
}
.labelt{
    text-align: left;
}
.tables{
    overflow-x:auto;
}
.labels{
    margin: 8px;
}
.divcontainer label{
    margin: 12px;
}
.divcontainers label{
    margin: 7px;
}
.divpara p{
    padding: 10px;   
}
.divpara h3{
    margin-bottom: 10px; 
    margin-top: 10px;  
}
.image{
    height:50vh;
    width:100%;
    border-radius:20px;
    position: relative;
    bottom:100px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
@media (max-width:1000px)
{
  .image{
    position: relative;
    bottom: 30px;
    width: 50%;
  }
}
@media (max-width:850px)
{
  .image{
    position: relative;
    bottom: 30px;
    width: 70%;
  }
}
@media (max-width:750px)
{
  .image{
    position: relative;
    bottom: 35px;
    width: 70%;
  }
}

.main-headings{
    text-wrap: nowrap;
    position: relative;
    left: -5%;
}
.cat-tag{
    position: relative;
    left: -10%;
}
@media (max-width:595px) {
    .image{
        height:50vh;
        width:100%;
        border-radius:20px;
        position: relative;
        bottom: 0px;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }  
    .main-headings{
        text-wrap: wrap;
        position: relative;
        left: 0%;
    }  
    .cat-tag{
        position: relative;
        left: 0%;
    }
}
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.my-masonry-grid {
  height: auto;
    position: fixed;
    display: flex;
    margin-left: -30px; 
    width: auto;
    animation: animateGrid 20s linear infinite;
  animation-fill-mode: both;
  }
  
  
  .my-masonry-grid_column {
    padding-left: 30px; 
    background-clip: padding-box;
  
  }
  @media(max-width:550px) {
    .my-masonry-grid_column {
        padding-left: 10px; 
        
    }
  }
 
@keyframes animateGrid {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-60%);
    }
  }
  .logo{
    position: absolute;
    top: 0px;
    height: 100vh;
    width: 85%;
    z-index: 9999;
  }
  .logo img {
    transition: 1s;
    position: relative  ;
    left: 28%;
    top: 27%;
    height: 40%;
    width:56%;
  }
  .myrows{
    opacity:"0.62";
  }
  @media (max-width:1195px) {
    .logo img {
        position: relative  ;
        left: 330px;
        top: 150px;
        height: 200px;
        width: 440px;
      }
  }
  @media (max-width:1024px) {
    .logo img {
        position: relative  ;
        left: 200px;
        top: 150px;
        height: 200px;
        width: 500px;
      }
      .make-up{
        position: relative;
        bottom:50px;
      }
  }
  @media (max-width:900px) {
    .logo img {
        position: relative  ;
        left: 250px;
        top: 150px;
        height: 300px;
        width: 450px;
      }
  }
  @media (max-width:750px) {
    .logo img {
        position: relative  ;
        left: 140px;
        top: 150px;
        height: 150px;
        width: 450px;
      }
  }
  @media (max-width:650px) {
    .logo img {
        position: relative  ;
        left: 80px;
        top: 150px;
        height: 200px;
        width: 400px;
      }
  }
  @media (max-width:560px) {
    .logo img {
        position: relative  ;
        left: 80px;
        top: 150px;
        height: 160px;
        width: 350px;
      }
  }
  @media (max-width:500px) {
    .logo img {
        position: relative  ;
        left: 50px;
        top: 150px;
        height: 150px;
        width: 350px;
      }
  }
  @media (max-width:450px) {
    .myrows{
      opacity: 0.5;
    }
    .logo img {
        position: relative;
        left:10%;
        top: 30%;
        height: 110px;
        width: 95%;
}
.make-up{
  position: relative;
  bottom: 70px;
}
.myicons img{
  width: 40px;
}}
.boxy{
    margin: 20px;
     position: fixed; /* for scroll */
}

@media (max-width:700px) {
  .portfolio_wrap .portfolio-feed .fadein .ms-p-content{
    height: 100%;
    width: 100%;
  }
  .portfolio_wrap .portfolio-feed .fadein .item--inner a{
    height: 100%;
    width: 100%;
  }
  .portfolio_wrap .portfolio-feed .item--inner{
    height: 110%;
    width: 100%;
  }
}

@media (max-width:650px) {
  
  .portfolio_wrap .portfolio-feed .item--inner{
    height: 105%;
    width: 100%;
  }
}
@media (max-width:450px) {
  
  .portfolio_wrap .portfolio-feed .item--inner{
    height: 100%;
    width: 100%;
  }
}
@media (max-width:350px) {
  
  .portfolio_wrap .portfolio-feed .item--inner{
    height: 102%;
    width: 100%;
  }
}
